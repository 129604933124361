import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";
import mainImage from '../../assets/img/north2/bg.png';
import searchBgImage from '../../assets/img/search-bg.png';
import treeImage from '../../assets/img/trees/t6.png';
import iconBg from '../../assets/img/iconbg.png';
import { Link } from "react-router-dom";
import bgCity from '../../assets/img/bg-city.png';

export const MainSection = styled(Box)`
  background-color: rgba(12,12,12,0.75);
  width: 100%;
  color: #fff;
  position: relative;
  :after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(${mainImage});
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;  
  }
`;
export const MainSectionContent = styled(Box)`
  max-width: 345px;
  max-height: 750px;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: column;
  align-items: baseline;
  justify-content: center;
  @media (max-width: 1024px) {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    max-width: 100%;
    justify-content: center;
    align-items: center;
  }
`;
export const MainTitle = styled(Box)`
  display: flex;
  img {
    width: 130px;
    height: auto;
  }
  @media (max-width: 1024px) {
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  span {
    font-size: 36px;
    display: block;
    line-height: 1.2;
    margin-left: 15px;
    text-transform: uppercase;
    @media (max-width: 1024px) {
      max-width: 170px;
    }
  }
`;
export const MainDescription = styled(Box)`
  font-size: 18px;
  margin-top: 10px;
  @media (max-width: 1024px) {
    margin-top: 15px;
    text-align: center;
  }
  span {
    font-size: 20px;
    @media (max-width: 1024px) {
      max-width: 170px;
    }
  }
`;
export const MainDescriptionSmall = styled(Box)`
  font-size: 16px;
  margin-top: 15px;
`;
export const MainBtn = styled(Button)`
  margin-top: 20px;
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 3px;
  padding: 0px;
  padding-bottom: 5px;
  border-bottom: 1px solid #fff;
  border-radius: 0px;
` as typeof Button;
export const SearchSection = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
    }
    :after {
      content: '';
      width: 815px;
      height: 294px;
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 105px;
      left: 9px;
      z-index: -1;
      background-image: url(${searchBgImage});
    }
`;
export const SearchTextContent = styled(Box)`
  max-width: 550px;
  position: relative;
`;
export const SearchHeader = styled(Box)`
  color: #244D76;
  font-size: 21px;
  font-weight: 500;
  span {
    display: block;
  }
`;
export const SearchText = styled(Typography)`
  margin-top: 20px;
  color: #244D76;
  font-size: 16px;
  font-weight: 400;
    margin-bottom: 20px;
`;

export const SearchIconItem = styled(Box)`
  margin-right: 40px;
  max-width: 150px;
  display: flex;
  flex-direction: column;
  color: #244D76;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  span { 
    margin-top: 10px;
    @media (max-width: 720px) {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 25px;
      font-size: 18px;
    }
  }
  svg {
    @media (max-width: 720px) {
      width: 80px;
      height: 80px;
    }
  }
  @media (max-width: 720px) {
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 35px;
  }
`;
export const SearchIcons = styled(Box)`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row;
  align-items: start;
  justify-content: space-between;
  @media (max-width: 720px) {
    flex-direction: column;
  }
  & div:last-of-type {
    margin-right: 0px;
  }
`;

export const PageDemo = styled(Box)`
  width: 450px;
  box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.25);
  max-width: 100%;
  background-image: url(${bgCity});
  border-radius: 10px;
  @media (max-width: 1024px) {
    margin-top: 60px;
  }
`;
export const PageDemoContainer = styled(Box)`
  margin-top: 85px;
  background: #FFF;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
`;
export const PhotoContainer = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  img {
    max-width: 245px;
    max-height: 245px;
    border-radius: 50%;
    margin-top: -18px;
  }
`;
export const PageDemoTitle = styled(Box)`
  color: #244D76;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
`;
export const PageDemoYears = styled(Box)`
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 10px;
`;
export const PageDemoText = styled(Box)`
  margin-top: 10px;
`;
export const PageDemoBtn = styled(Link)`
  color: rgba(36, 77, 118, 0.85);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
  display: block;
  text-decoration: none;
`;

export const DesignSection = styled(Box)`
  max-width: 980px;
  width: 100%;
  padding-left: 100px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-right: 100px;
  background: #FAFAFA;
  box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: -40px;
  position: relative;
  z-index: 1;
  @media (max-width: 720px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;
export const DesignTitle = styled(Typography)`
  color: #244D76;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const DesignDescription = styled(Box)`
  margin-top: 15px;
  color: #244D76;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const DesignImages = styled(Box)`
  margin-top: 20px;
  img {
    width: 150px;
    height: 150px;
  }
`;


export const StoreSection = styled(Box)`
  background: #EEE;
  padding-top: 100px;
`;
export const StoreTitle = styled(Box)`
  color: #244D76;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const StoreDescription = styled(Box)`
  color: #244D76;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 35px;
  span {
    display: block;
  }
`;
export const StoreIcons = styled(Box)`
  margin: 0 auto;
  margin-top: 10px;
  max-width: 1300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const StoreIconsItem = styled(Box)`
  max-width: 400px;
  display: flex;
  margin-top: 25px;
  @media (max-width: 1024px) {
    max-width: calc(50% - 20px);
  }
  @media (max-width: 729px) {
    max-width: 100%;
  }
`;
export const StoreIconsImageContainer = styled(Box)`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${iconBg});
  background-color: #F8FAFB;
  border: 2px solid #7B93AC;
  flex: none;
  z-index: 2;
`;
export const StoreIconsItemContent = styled(Box)`
  margin-left: -50px;
  z-index: 1;
  position: relative;
`;
export const StoreContainer =  styled(Box)`
  max-width: 1300px;
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 70px;
`;
export const StoreIconsItemHeader = styled(Box)`
  background-color: #F8FAFB;
  padding-left: 60px;
  margin-right: -10px;
  font-weight: 600;
`;
export const StoreIconsItemText = styled(Box)`
  background-color: #F8FAFB;
  padding-left: 60px;
  margin-top: 1px;
  height: 68px;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 10px;
`;

export const TreeSection = styled(Box)`
  background-image: url(${treeImage});
    background-size: contain;
  background-position: 108%;
  background-repeat: no-repeat;
  height: 250px;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  display: flex;
  background-color: #FAFAFA;
  box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.25);
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 750px;
  margin: 0 auto;
  margin-top: -30px;
  position: relative;
  z-index: 2;
  @media (max-width: 720px) {
    margin-left: 0px;
    margin-right: 0px;
    height: 630px;
    justify-content: flex-end;
    background-position: 50% 10px;
    background-size: 95%;
  }
`;
export const TreeTitle = styled(Typography)`
  color: #244D76;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const TreeDescription = styled(Box)`
  color: #244D76;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 15px;
`;
export const TreeBtn = styled(Button)`
  margin-top: 35px;
`;

export const StepsSection = styled(Box)`
`;

export const FAQSection = styled(Box)`
  background: #EEE;
  padding-top: 80px;
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
  margin-top: -30px;
`;
export const FAQTitle = styled(Typography)`
  color: #244D76;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const FAQContainer = styled(Box)`
  margin-top: 25px;
`;

export const ActionSection = styled(Box)`
  max-width: 100%;
  background-color: hsla(210, 53%, 30%, 0.95);
  margin: 0 auto;
  height: 290px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 720px) {
    text-align: center;
  }
  :after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(${mainImage});
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;  
  }
`;
export const ActionTitle = styled(Typography)`
  color: #FFF;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const ActionText = styled(Box)`
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 15px;
  text-transform: uppercase;
`;
export const ActionBtn = styled(Button)`
  margin-top: 25px;
`;