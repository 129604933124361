import React, {useEffect, useRef, useState} from 'react';
import {Box, Container, FormControl, Grid, InputLabel, MenuItem, Select, styled, TextField} from '@mui/material';
import treeBlank from '../../assets/img/trees/blank.png';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {
    LoadedImage,
    LoadedImageBox,
    LoadedImageRemove,
    LoadedImagesContainer,
    MainTitle,
    QRCodeContainer,
    TreeBlankImg,
    CustomQRCodeExampleContainer
} from './styles';
import Button from '@mui/material/Button';
import {TextareaAutosize as BaseTextareaAutosize} from '@mui/base/TextareaAutosize';
import client, {UI_HOST} from "../../services/auth/ApiService/client";
import MapSelect from "../../components/MapSelect";
import TreeAutocomplete from "../../components/TreeAutocomplete/TreeAutocomplete";
import QRCodeConfigurator, {TypeConfigurator} from "../../components/QRCodeConfigurator";
import QRCodeStyling, {
    DotType,
    Options
} from 'qr-code-styling';
import {setupImageHeightWidthByContainer} from "../../utils/billing";

export interface IAllowFields {
    bio?: boolean;
    epitafia?: boolean;
    gallery?: boolean;
    video?: boolean;
    map?: boolean;
    media?: boolean;
    qr?: boolean;
    tree?: boolean;
    bg?: boolean;
    customImage?: boolean;
    solder?: boolean;
}

export interface ITarriff {
    allowFields: IAllowFields;
    createdAt: string;
    hide: boolean;
    id: number;
    name: string;
    price: number;
    updatedAt: string;
}
const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};
const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.87);
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);
export interface IAnketa {
    id?: string;
    name: string;
    rang: string;
    photo: { buffer?: File, src: string };
    bg: { buffer?: File, src: string };
    dateBorn: string;
    dateEnd: string;
    years: string;
    quote: string;
    quoteName: string;
    createdAt: string,
    subData: {
        placeBorn: string;
        placeEnd: string;
        brothers: string;
        children: string;
        placeLive: string;
        parents: string;
        partner: string;
        education: string;
    };
    bio: {
        name: string,
        text: string,
        images: { buffer?: File, src: string }[],
    }[];
    user: any;
    gallery: { buffer?: File, src: string }[];
    video: { src: string, buffer?: File, type?: string };
    graveyard: {
        description: string;
        name: string;
    };
    userId: number | null;
    mediaLinks: string[];
    socialLinks: string[];
    QRImage: { buffer?: File, src: string };
    customImage: { buffer?: File, src: string, width?: number, height?: number };
    customImageText: string;
    customImageText2: string;
    customImageText3: string;
    customQRCode: {
        type: string,
        color?: string,
        cornersType?: string,
        cornersColor?: string,
    },
    hidden: boolean;
    orders: any[];
    isTrial: boolean;
    isSolder: boolean;
    status: string;
    honor: {
        name: string,
        text: string,
        images: { buffer?: File, src: string }[],
    }[]
    honorWay: {
        name: string,
        text: string,
        images: { buffer?: File, src: string }[],
    }[];
    tree: any[];
    tariffId?: string;
    orderId?: string;
    coordinates?: {
        address: string,
        coords: string[],
    };
}
export const initialState: IAnketa = {
    name: '',
    rang: '',
    createdAt: '',
    photo: {src: ''},
    bg: {src: ''},
    dateBorn: '',
    dateEnd: '',
    years: '',
    quote: '',
    quoteName: '',
    subData: {
        placeBorn: '',
        placeEnd: '',
        brothers: '',
        children: '',
        placeLive: '',
        parents: '',
        partner: '',
        education: '',
    },
    bio: [],
    user: {},
    gallery: [],
    video: {src: ''},
    graveyard: {
        description: '',
        name: '',
    },
    userId: null,
    mediaLinks: [],
    socialLinks: [],
    QRImage: {src: ''},
    hidden: false,
    orders: [],
    isTrial: false,
    isSolder: false,
    status: 'active',
    honor: [],
    honorWay: [],
    tree: [],
    customImage: {src: ''},
    customImageText: '',
    customImageText2: '',
    customImageText3: '',
    customQRCode: {
        type: 'square' as DotType,
        color: '#000000',
        cornersType: 'square',
        cornersColor: '#00000',
    }
}
export type IRelationsResult = {
    anketa: IAnketa;
    relation: string;
    position: number;
}

export interface ITree {
    id: string;
    anketaToId: string;
    anketaId: string;
    name: string;
    position: number;
    status: string;
}

const LoadButton = styled(Button)`
    font-size: 12px;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 160px;
    height: 160px;
    margin: 5px;
` as typeof Button;

const ChangePage = () => {
    const navigate = useNavigate();
    const [allowFields, setAllow] = React.useState<IAllowFields>({})
    const [anketa, setAnketa] = React.useState<IAnketa>(initialState)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [qrSrc, setQRSrc] = React.useState<string | undefined>(undefined)
    const refQR = useRef(null)

    const params = useParams()
    const { tariffid = '', id = '' } = params

    const [gParams] = useSearchParams()
    const r = gParams.get('r')

    const [treePositions, setTreePositions] = React.useState<number[]>([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19])
    const initialRelation = {
        anketa: anketa,
        relation: '',
        position: 1,
    };
    const [relationsResult, setRelationsResult] = React.useState<IRelationsResult[]>([])
    const [options, setOptions] = React.useState({
        width: 100,
        height: 100,
        image: 'https://storage.yandexcloud.net/northmemoryhotstore/logo-original.png',
        data: id ? `${UI_HOST}/${anketa.isSolder ? 'solder' : 'anketa'}/${id}` : `${UI_HOST}`,
        margin: 2,
        dotsOptions: {
            color: '#000000',
            type: 'square',
        },
        imageOptions: {
            crossOrigin: 'anonymous',
            margin: 5,
        },
    })

    const [newCoords, setNewCoords] = useState([69.050867, 33.256601]);
    const [address, setAddress] = useState("");

    const name = anketa?.name ?? 'Этот момент памяти';

    useEffect(() => {
        const tId = tariffid;
        const loadData = () => {
            if (tId) {
                client
                    .get(`/tariff/${tId}`)
                    .then((response) => {
                        const data = response.data as ITarriff;
                        console.log(data?.allowFields)
                        setAllow(data.allowFields)
                        setRelationsResult([{
                            anketa: {
                                ...initialState,
                                name: 'Этот момент памяти',
                            },
                            relation: '',
                            position: 1,
                        }])
                        setNewCoords([69.050867, 33.256601]);
                    })
                    .catch((error) => {
                        console.log('error')
                        console.log(error)
                    })
            } else {
                client
                    .get(`/anketas/${id}`)
                    .then((response) => {
                        const data = response.data?.anketa as IAnketa;
                        setAnketa(data);
                        if (response?.data?.relations && response?.data?.trees) {
                            const rRelations = response?.data?.trees.map((tree: ITree) => {
                                const rAnketa = response?.data?.relations.find((relation: any) => relation.id === tree.anketaToId);
                                return {
                                    anketa: rAnketa,
                                    relation: tree.name,
                                    position: tree.position,
                                }
                            })
                            setRelationsResult(rRelations);
                            if (data?.coordinates?.coords) {
                                const x = Number(data.coordinates.coords[0]);
                                const y = Number(data.coordinates.coords[1]);
                                setNewCoords([x,y]);
                                setAddress(data.coordinates.address);
                            }
                        }
                        client.get(`/tariff/${data?.tariffId}`)
                            .then((response) => {
                                const data = response.data as ITarriff;
                                setAllow(data.allowFields);
                            })
                        console.log('current',refQR?.current)
                        if (refQR?.current) {
                            qrCode.append(refQR?.current)
                        }
                        console.log(data)
                    })
            }
        }
        loadData()
        if (refQR?.current) {
            qrCode.append(refQR.current)
            getQRCodeUrl()
        }
    }, []);

    const anketaToFormData = (obj: any, form?: any, namespace?: any) => {
        const formData = form || new FormData()
        for (const property in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, property)) {
                const formKey = namespace ? `${namespace}[${property}]` : property
                if (obj[property] instanceof File) {
                    formData.append(formKey, obj[property] as Blob)
                } else if (Array.isArray(obj[property])) {
                    obj[property].forEach((element: any, index: any) => {
                        const tempFormKey = `${formKey}[${index}]`
                        if (typeof element === 'object' && !(element instanceof File)) {
                            anketaToFormData(element, formData, tempFormKey)
                        } else {
                            formData.append(tempFormKey, element)
                        }
                    })
                } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                    anketaToFormData(obj[property], formData, formKey)
                } else {
                    formData.append(formKey, obj[property])
                }
            }
        }

        return formData
    }

    const getQRCodeData = async () => {
        const data = await qrCode.getRawData('png');
        console.log(data);
        return data;
    }
    const getQRCodeUrl = async () => {
        const data = await getQRCodeData();
        if (!data) return undefined;
        const src = URL.createObjectURL(data);
        setQRSrc(src);
    }

    useEffect(() => {
        if (tariffid) {
            const currentRelation = relationsResult.find((relation) => !relation?.anketa?.id) ?? initialRelation
            setRelationsResult([
                {
                    ...currentRelation,
                    anketa: currentRelation?.anketa ?? initialState
                },
                ...relationsResult.filter((relation) => relation?.anketa?.id),
            ])
        }
    }, [name]);

    useEffect(() => {
        console.log('check')
        qrCode.update({
            dotsOptions: {
                type: anketa?.customQRCode?.type as DotType ?? 'square',
            }
        })
        if (refQR.current) {
            qrCode.append(refQR.current);
        }
    }, [anketa?.customQRCode?.type])

    const handleSubmit = (e: any) => {
        e.preventDefault()
        const formData = anketaToFormData(anketa)
        if (relationsResult?.length > 1) {
            relationsResult.map((relation, key) => {
                formData.append(`relations[${key}][position]`, relation.position);
                formData.append(`relations[${key}][relation]`, relation.relation);
                formData.append(`relations[${key}][id]`, relation.anketa.id);
            })
        }
        if (r) {
            formData.append(`referalId`, r);
        }
        if (tariffid) {
            formData.append('tariffId', tariffid);
        }
        if (address && newCoords) {
            formData.append('map[address]', address);
            formData.append('map[coords][0]', newCoords[0]);
            formData.append('map[coords][1]', newCoords[1]);
        }
        if (tariffid) {
            client
                .post('/anketas/new', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .catch((error) => {
                    console.log(error)
                })
                .then((response) => {
                    navigate('/profile')
                })
        } else {
            client
                .post(`/anketas/${id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .catch((error) => {
                    console.log(error)
                })
                .then((response) => {
                    navigate('/profile')
                })
        }
    }

    useEffect(() => {
        getQRCodeUrl();
    }, [anketa?.customQRCode?.type])

    const [qrCode] = useState<QRCodeStyling>(new QRCodeStyling(options as Options));

  return (
    <>
        <Container style={{marginTop: '120px', paddingLeft: '0px', paddingRight: '0px',}}>
            <MainTitle variant="h4" sx={{marginBottom: '40px'}}>Новая анкета</MainTitle>
            <Grid
                component="form"
                noValidate
                autoComplete="off"
                sx={{marginLeft: 'auto', marginRight: 'auto'}}
            >
                <h4>Основная информация</h4>
                <FormControl fullWidth sx={{m: 1}}>
                    <TextField id="outlined-basic1" label="ФИО" variant="outlined" value={anketa.name}
                               onChange={(e) => {
                                   setAnketa({
                                       ...anketa,
                                       name: e.target.value,
                                   })
                               }}/>
                </FormControl>
                {allowFields?.solder && (<>
                    <FormControl fullWidth sx={{m: 1}}>
                        <TextField id="outlined-basic1" label="Звание" variant="outlined" value={anketa.rang}
                                   onChange={(e) => {
                                       setAnketa({
                                           ...anketa,
                                           rang: e.target.value,
                                       })
                                   }}/>
                    </FormControl>
                </>)}
                <FormControl fullWidth sx={{m: 1}}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                            <TextField fullWidth id="outlined-basic2" label="Дата рождения" variant="outlined"
                                       value={anketa.dateBorn} onChange={(e) => {
                                setAnketa({
                                    ...anketa,
                                    dateBorn: e.target.value,
                                })
                            }}/>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField fullWidth id="outlined-basic2" label="Дата смерти" variant="outlined"
                                       value={anketa.dateEnd} onChange={(e) => {
                                setAnketa({
                                    ...anketa,
                                    dateEnd: e.target.value,
                                })
                            }}/>
                        </Grid>
                    </Grid>
                </FormControl>
                <FormControl fullWidth sx={{m: 1}}>
                    <TextField id="outlined-basic2" label="Количество лет" variant="outlined" value={anketa.years}
                               onChange={(e) => {
                                   setAnketa({
                                       ...anketa,
                                       years: e.target.value,
                                   })
                               }}/>
                </FormControl>

                <h4>Фотография</h4>
                <FormControl fullWidth sx={{m: 1, flexDirection: 'row', flexWrap: 'wrap'}}>
                    <LoadButton
                        variant="contained"
                        component="label"
                    >
                        <svg fill="#ffffff" width="50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/>
                        </svg>
                        Загрузить изображения
                        <input
                            type="file"
                            multiple={true}
                            hidden
                            onChange={(e) => {
                                if (!e.target?.files?.length) return;
                                const src = URL.createObjectURL(e.target.files[0])
                                setAnketa({
                                    ...anketa,
                                    photo: {
                                        buffer: e.target.files[0],
                                        src
                                    },
                                })
                            }}
                        />
                    </LoadButton>
                    {anketa?.photo?.src && <img src={anketa?.photo?.src} style={{
                        height: '160px',
                        margin: '5px',
                        objectFit: 'cover',
                        display: 'inline-block'
                    }}/>}
                </FormControl>

                <h4>Фон</h4>
                <FormControl fullWidth sx={{m: 1, flexDirection: 'row', flexWrap: 'wrap'}}>
                    <LoadButton
                        variant="contained"
                        component="label"
                    >
                        <svg fill="#ffffff" width="50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/>
                        </svg>
                        Загрузить изображения
                        <input
                            type="file"
                            multiple={true}
                            hidden
                            onChange={(e) => {
                                if (!e.target?.files?.length) return;
                                const src = URL.createObjectURL(e.target.files[0])
                                setAnketa({
                                    ...anketa,
                                    bg: {buffer: e.target.files[0], src},
                                })
                            }}
                        />
                    </LoadButton>
                    {anketa?.bg?.src && <img src={anketa?.bg?.src} style={{
                        height: '160px',
                        margin: '5px',
                        objectFit: 'cover',
                        display: 'inline-block'
                    }}/>}
                </FormControl>

                {allowFields?.epitafia && (<>
                    <h4>Эпитафия</h4>
                    <FormControl fullWidth sx={{m: 1}}>
                        <TextField value={anketa.quoteName} onChange={
                            (e) => setAnketa({
                                ...anketa,
                                quoteName: e.target.value,
                            })
                        } id="outlined-basic2" label="Автор эпитафии" variant="outlined"/>
                    </FormControl>
                    <FormControl fullWidth sx={{m: 1}}>
                        <Textarea value={anketa.quote} onChange={
                            (e) => setAnketa({
                                ...anketa,
                                quote: e.target.value,
                            })
                        } aria-label="minimum height" minRows={5} placeholder="Краткая эпитафия"/>
                    </FormControl>
                </>)}

                <h4>Краткая информация</h4>
                <FormControl fullWidth sx={{m: 1}}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                            <TextField fullWidth id="outlined-basic2" value={anketa.subData.placeBorn} onChange={
                                (e) => setAnketa({
                                    ...anketa,
                                    subData: {
                                        ...anketa.subData,
                                        placeBorn: e.target.value,
                                    }
                                })
                            } label="Место рождения" variant="outlined"/>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField fullWidth value={anketa.subData.placeLive} onChange={
                                (e) => setAnketa({
                                    ...anketa,
                                    subData: {
                                        ...anketa.subData,
                                        placeLive: e.target.value,
                                    }
                                })
                            } id="outlined-basic2" label="Место проживания" variant="outlined"/>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField fullWidth value={anketa.subData.placeEnd} onChange={
                                (e) => setAnketa({
                                    ...anketa,
                                    subData: {
                                        ...anketa.subData,
                                        placeEnd: e.target.value,
                                    }
                                })
                            } id="outlined-basic2" label="Место захоронения" variant="outlined"/>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField fullWidth id="outlined-basic2" label="Родители" variant="outlined"
                                       value={anketa.subData.parents} onChange={
                                (e) => setAnketa({
                                    ...anketa,
                                    subData: {
                                        ...anketa.subData,
                                        parents: e.target.value,
                                    }
                                })
                            }/>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField fullWidth value={anketa.subData.brothers} onChange={
                                (e) => setAnketa({
                                    ...anketa,
                                    subData: {
                                        ...anketa.subData,
                                        brothers: e.target.value,
                                    }
                                })
                            } id="outlined-basic2" label="Братья и сестры" variant="outlined"/>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField fullWidth value={anketa.subData.partner} onChange={
                                (e) => setAnketa({
                                    ...anketa,
                                    subData: {
                                        ...anketa.subData,
                                        partner: e.target.value,
                                    }
                                })
                            } id="outlined-basic2" label="Супруга / Супруг" variant="outlined"/>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField fullWidth id="outlined-basic2" value={anketa.subData.children} onChange={
                                (e) => setAnketa({
                                    ...anketa,
                                    subData: {
                                        ...anketa.subData,
                                        children: e.target.value,
                                    }
                                })
                            } label="Дети" variant="outlined"/>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField fullWidth id="outlined-basic2" label="Образование" variant="outlined"
                                       value={anketa.subData.education} onChange={
                                (e) => setAnketa({
                                    ...anketa,
                                    subData: {
                                        ...anketa.subData,
                                        education: e.target.value,
                                    }
                                })
                            }/>
                        </Grid>
                    </Grid>
                </FormControl>

                {allowFields?.tree && (<>
                    <h4>Родственники</h4>
                    <FormControl fullWidth sx={{m: 1}}>
                        <Grid container spacing={2}>
                            {relationsResult && relationsResult?.sort((a, b) => a.anketa?.id === id ? -1 : 1)?.map((item, key) => (
                                <React.Fragment key={`${item.anketa.name}-${key}`}>
                                    <Grid item xs={6} md={6}>
                                        <TextField fullWidth disabled label="ФИО" variant="outlined"
                                                   value={item.anketa.name}/>
                                    </Grid>
                                    <Grid item xs={2} md={2}>
                                        <TextField fullWidth label="Положение в семье" variant="outlined"
                                                   onChange={(e) => {
                                                       const newRelations = [...relationsResult];
                                                       newRelations[key].relation = e.target.value;
                                                       setRelationsResult(newRelations);
                                                   }} value={item?.relation}/>
                                    </Grid>
                                    <Grid item xs={2} md={2}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Позиция на дереве</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Позиция в дереве"
                                                onChange={(e) => {
                                                    const newRelations = [...relationsResult];
                                                    newRelations[key].position = Number(e.target.value);
                                                    setRelationsResult(newRelations);
                                                }}
                                                value={item.position}
                                            >
                                                {treePositions.map((pos, key) => (
                                                    <MenuItem key={`${pos}-${key}-tree`} value={pos}>{pos}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {((!tariffid && item?.anketa?.id !== id) || (tariffid && item?.anketa?.id)) && (
                                        <Grid item xs={2} md={2}>
                                            <Button
                                                variant="contained"
                                                component="label"
                                                fullWidth
                                                sx={{height: '56px'}}
                                                onClick={(e) => {
                                                    const newRelations = [];
                                                    if (relationsResult?.length === 0) {
                                                        newRelations.push(initialRelation);
                                                    }
                                                    newRelations.push(...relationsResult);
                                                    newRelations.splice(key, 1);
                                                    setRelationsResult(newRelations);
                                                }}
                                            >
                                                Удалить
                                            </Button>
                                        </Grid>
                                    )}
                                </React.Fragment>
                            ))}
                            <Grid item xs={12} md={12}>
                                <TreeAutocomplete relationsResult={relationsResult}
                                                  setRelationsResult={setRelationsResult}/>
                            </Grid>
                        </Grid>
                    </FormControl>
                    <h4>Позиции на дереве</h4>
                    <TreeBlankImg src={treeBlank}/>
                </>)}

                {allowFields?.bio && (<>
                    <h4>Биография</h4>
                    {anketa.bio && anketa.bio.map((bio, key) => (
                        <FormControl fullWidth sx={{m: 1}} key={`${key}-bio`}>
                            <TextField value={bio.name} onChange={
                                (e) => setAnketa({
                                    ...anketa,
                                    bio: anketa.bio.map((b, k) => {
                                        if (k === key) {
                                            return {
                                                ...b,
                                                name: e.target.value,
                                            }
                                        }
                                        return b;
                                    })
                                })
                            } id="outlined-basic2" label="Название блока" variant="outlined"/>
                            <Textarea aria-label="minimum height" placeholder="Текст" minRows={5} sx={{mb: 2}}
                                      value={bio.text} onChange={(e) => {
                                setAnketa({
                                    ...anketa,
                                    bio: anketa.bio.map((b, k) => {
                                        if (k === key) {
                                            return {
                                                ...b,
                                                text: e.target.value,
                                            }
                                        }
                                        return b;
                                    })
                                })
                            }}/>
                            <LoadedImagesContainer>
                                <LoadButton
                                    variant="contained"
                                    component="label"
                                    sx={{mb: 1}}
                                >
                                    <svg fill="#ffffff" width="50" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 512 512">
                                        <path
                                            d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/>
                                    </svg>
                                    Загрузить изображения
                                    <input
                                        type="file"
                                        multiple={true}
                                        hidden
                                        onChange={(e) => {
                                            if (!e.target?.files?.length) return;
                                            const fileList: File[] = Array.from(e.target.files)
                                            const images: { buffer: File, src: string }[] = fileList.map((file) => {
                                                return {
                                                    buffer: file,
                                                    src: URL.createObjectURL(file),
                                                }
                                            })
                                            setAnketa({
                                                ...anketa,
                                                bio: anketa.bio.map((b, k) => {
                                                    if (k === key) {
                                                        return {
                                                            ...b,
                                                            images: [...b.images, ...images],
                                                        }
                                                    }
                                                    return b;
                                                })
                                            })
                                        }}
                                    />
                                </LoadButton>
                                {bio.images && bio.images.map((img, k) => (
                                    <LoadedImageBox key={`${key}-${k}-img`}>
                                        <LoadedImage src={img.src}/>
                                        <LoadedImageRemove onClick={(e) => {
                                            const newBio = [...anketa.bio];
                                            newBio[key].images.splice(k, 1)
                                            setAnketa({
                                                ...anketa,
                                                bio: newBio,
                                            })
                                        }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                <path
                                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                                            </svg>
                                        </LoadedImageRemove>
                                    </LoadedImageBox>
                                ))}
                            </LoadedImagesContainer>
                            <Button
                                variant="contained"
                                component="label"
                                onClick={() => {
                                    const newBio = [...anketa.bio];
                                    newBio.splice(key, 1)
                                    setAnketa({
                                        ...anketa,
                                        bio: newBio,
                                    })
                                }}
                            >
                                Убрать
                            </Button>
                        </FormControl>
                    ))}
                    <Button
                        variant="contained"
                        component="label"
                        onClick={() => {
                            const newBio = [...anketa.bio];
                            newBio.push({
                                name: '',
                                text: '',
                                images: [],
                            })
                            setAnketa({
                                ...anketa,
                                bio: newBio,
                            })
                        }}
                    >
                        Добавить
                    </Button>
                </>)}

                {allowFields?.solder && (<>
                    <h4>Награды</h4>
                    {anketa.honor && anketa.honor.map((honor, key) => (
                        <FormControl fullWidth sx={{m: 1}} key={`${key}-bio`}>
                            <TextField value={honor.name} onChange={
                                (e) => setAnketa({
                                    ...anketa,
                                    honor: anketa.honor.map((b, k) => {
                                        if (k === key) {
                                            return {
                                                ...b,
                                                name: e.target.value,
                                            }
                                        }
                                        return b;
                                    })
                                })
                            } id="outlined-basic2" label="Наименование награды" variant="outlined"/>
                            <Textarea aria-label="minimum height" placeholder="История получения награды" minRows={5}
                                      sx={{mb: 2}} value={honor.text} onChange={(e) => {
                                setAnketa({
                                    ...anketa,
                                    honor: anketa.honor.map((b, k) => {
                                        if (k === key) {
                                            return {
                                                ...b,
                                                text: e.target.value,
                                            }
                                        }
                                        return b;
                                    })
                                })
                            }}/>
                            <LoadedImagesContainer>
                                <LoadButton
                                    variant="contained"
                                    component="label"
                                    sx={{mb: 1}}
                                >
                                    <svg fill="#ffffff" width="50" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 512 512">
                                        <path
                                            d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/>
                                    </svg>
                                    Загрузить изображения
                                    <input
                                        type="file"
                                        multiple={true}
                                        hidden
                                        onChange={(e) => {
                                            if (!e.target?.files?.length) return;
                                            const fileList: File[] = Array.from(e.target.files)
                                            const images: { buffer: File, src: string }[] = fileList.map((file) => {
                                                return {
                                                    buffer: file,
                                                    src: URL.createObjectURL(file),
                                                }
                                            })
                                            setAnketa({
                                                ...anketa,
                                                honor: anketa.honor.map((b, k) => {
                                                    if (k === key) {
                                                        return {
                                                            ...b,
                                                            images: [...b.images, ...images],
                                                        }
                                                    }
                                                    return b;
                                                })
                                            })
                                        }}
                                    />
                                </LoadButton>
                                {honor.images && honor.images.map((img, k) => (
                                    <LoadedImageBox key={`${key}-${k}-img`}>
                                        <LoadedImage src={img.src}/>
                                        <LoadedImageRemove onClick={(e) => {
                                            const honor = [...anketa.bio];
                                            honor[key].images.splice(k, 1)
                                            setAnketa({
                                                ...anketa,
                                                honor: honor,
                                            })
                                        }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                <path
                                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                                            </svg>
                                        </LoadedImageRemove>
                                    </LoadedImageBox>
                                ))}
                            </LoadedImagesContainer>
                            <Button
                                variant="contained"
                                component="label"
                                onClick={() => {
                                    const honor = [...anketa.honor];
                                    honor.splice(key, 1)
                                    setAnketa({
                                        ...anketa,
                                        honor: honor,
                                    })
                                }}
                            >
                                Убрать
                            </Button>
                        </FormControl>
                    ))}
                    <Button
                        variant="contained"
                        component="label"
                        onClick={() => {
                            const honor = [...anketa.honor];
                            honor.push({
                                name: '',
                                text: '',
                                images: [],
                            })
                            setAnketa({
                                ...anketa,
                                honor: honor,
                            })
                        }}
                    >
                        Добавить
                    </Button>
                </>)}

                {allowFields?.solder && (<>
                    <h4>Боевой путь</h4>
                    {anketa.honorWay && anketa.honorWay.map((honorWay, key) => (
                        <FormControl fullWidth sx={{m: 1}} key={`${key}-bio`}>
                            <TextField value={honorWay.name} onChange={
                                (e) => setAnketa({
                                    ...anketa,
                                    honorWay: anketa.honorWay.map((b, k) => {
                                        if (k === key) {
                                            return {
                                                ...b,
                                                name: e.target.value,
                                            }
                                        }
                                        return b;
                                    })
                                })
                            } id="outlined-basic2" label="Наименование награды" variant="outlined"/>
                            <Textarea aria-label="minimum height" placeholder="История получения награды" minRows={5}
                                      sx={{mb: 2}} value={honorWay.text} onChange={(e) => {
                                setAnketa({
                                    ...anketa,
                                    honorWay: anketa.honorWay.map((b, k) => {
                                        if (k === key) {
                                            return {
                                                ...b,
                                                text: e.target.value,
                                            }
                                        }
                                        return b;
                                    })
                                })
                            }}/>
                            <LoadedImagesContainer>
                                <LoadButton
                                    variant="contained"
                                    component="label"
                                    sx={{mb: 1}}
                                >
                                    <svg fill="#ffffff" width="50" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 512 512">
                                        <path
                                            d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/>
                                    </svg>
                                    Загрузить изображения
                                    <input
                                        type="file"
                                        multiple={true}
                                        hidden
                                        onChange={(e) => {
                                            if (!e.target?.files?.length) return;
                                            const fileList: File[] = Array.from(e.target.files)
                                            const images: { buffer: File, src: string }[] = fileList.map((file) => {
                                                return {
                                                    buffer: file,
                                                    src: URL.createObjectURL(file),
                                                }
                                            })
                                            setAnketa({
                                                ...anketa,
                                                honorWay: anketa.honorWay.map((b, k) => {
                                                    if (k === key) {
                                                        return {
                                                            ...b,
                                                            images: [...b.images, ...images],
                                                        }
                                                    }
                                                    return b;
                                                })
                                            })
                                        }}
                                    />
                                </LoadButton>
                                {honorWay.images && honorWay.images.map((img: any, k: any) => (
                                    <LoadedImageBox key={`${key}-${k}-img`}>
                                        <LoadedImage src={img.src}/>
                                        <LoadedImageRemove onClick={(e) => {
                                            const honorWay = [...anketa.bio];
                                            honorWay[key].images.splice(k, 1)
                                            setAnketa({
                                                ...anketa,
                                                honorWay: honorWay,
                                            })
                                        }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                <path
                                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                                            </svg>
                                        </LoadedImageRemove>
                                    </LoadedImageBox>
                                ))}
                            </LoadedImagesContainer>
                            <Button
                                variant="contained"
                                component="label"
                                onClick={() => {
                                    const honorWay = [...anketa.honorWay];
                                    honorWay.splice(key, 1)
                                    setAnketa({
                                        ...anketa,
                                        honorWay: honorWay,
                                    })
                                }}
                            >
                                Убрать
                            </Button>
                        </FormControl>
                    ))}
                    <Button
                        variant="contained"
                        component="label"
                        onClick={() => {
                            const honorWay = [...anketa.honorWay];
                            honorWay.push({
                                name: '',
                                text: '',
                                images: [],
                            })
                            setAnketa({
                                ...anketa,
                                honorWay: honorWay,
                            })
                        }}
                    >
                        Добавить
                    </Button>
                </>)}

                {allowFields?.gallery && (<>
                    <h4>Галерея</h4>
                    <FormControl fullWidth sx={{m: 1}}>
                        <LoadedImagesContainer>
                            <LoadButton
                                variant="contained"
                                component="label"
                                sx={{mb: 1}}
                            >
                                <svg fill="#ffffff" width="50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path
                                        d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/>
                                </svg>
                                Загрузить изображения
                                <input
                                    type="file"
                                    multiple={true}
                                    hidden
                                    onChange={(e) => {
                                        if (!e.target?.files?.length) return;
                                        const fileList: File[] = Array.from(e.target.files)
                                        const images: { buffer: File, src: string }[] = fileList.map((file) => {
                                            return {
                                                buffer: file,
                                                src: URL.createObjectURL(file),
                                            }
                                        })
                                        setAnketa({
                                            ...anketa,
                                            gallery: [
                                                ...(anketa?.gallery ?? []),
                                                ...images,
                                            ]
                                        })
                                    }}
                                />
                            </LoadButton>
                            {anketa.gallery && anketa.gallery.map((img, k) => (
                                <LoadedImageBox key={`${img.src}-${k}-gallery`}>
                                    <LoadedImage src={img.src}/>
                                    <LoadedImageRemove onClick={(e) => {
                                        const newBio = [...anketa.gallery];
                                        newBio.splice(k, 1)
                                        setAnketa({
                                            ...anketa,
                                            gallery: newBio,
                                        })
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                            <path
                                                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                                        </svg>
                                    </LoadedImageRemove>
                                </LoadedImageBox>
                            ))}
                        </LoadedImagesContainer>
                    </FormControl>
                </>)}
                {allowFields?.video && (<>
                    <h4>Видео</h4>
                    <FormControl fullWidth sx={{m: 1}}>
                        <LoadButton
                            variant="contained"
                            component="label"
                        >
                            <svg fill="#ffffff" width="50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/>
                            </svg>
                            Загрузить видео
                            <input
                                type="file"
                                hidden
                                onChange={(e) => {
                                    if (!e.target?.files?.length) return;
                                    const src = URL.createObjectURL(e.target.files[0])
                                    setAnketa({
                                        ...anketa,
                                        video: {buffer: e.target.files[0], src, type: e.target.files[0].type},
                                    })
                                }}
                            />
                        </LoadButton>
                        {anketa?.video?.src && (
                            <video width="100%" controls>
                                <source
                                    src={anketa?.video?.src}
                                    type={
                                        ['video/mp4', 'video/webm', 'video/ogg'].includes(anketa?.video?.type as string)
                                            ? anketa?.video?.type as string
                                            : 'video/mp4'
                                    }
                                />
                                Ваш браузер не поддерживает видео тег.
                            </video>
                        )}
                    </FormControl>
                </>)}

                {allowFields?.media && (<>
                    <h4>Видео из соц. сетей</h4>
                    <FormControl fullWidth sx={{m: 1}}>
                        <Grid container spacing={2}>
                            {anketa.mediaLinks && anketa.mediaLinks.map((link, key) => (<>
                                <Grid item xs={12} md={12}>
                                    <TextField fullWidth label="Вставьте код iframe из соц сети" variant="outlined"
                                               value={link} onChange={(e) => {
                                        const newLinks = [...anketa.mediaLinks];
                                        newLinks[key] = e.target.value;
                                        setAnketa({
                                            ...anketa,
                                            mediaLinks: newLinks,
                                        })
                                    }}/>
                                    {link && link !== '' && (
                                        <Box sx={{mt: 1}} dangerouslySetInnerHTML={{__html: link}}></Box>)}
                                </Grid>
                            </>))}
                        </Grid>
                        <Button
                            variant="contained"
                            sx={{mt: 2}}
                            onClick={() => {
                                const newLinks = [...anketa?.mediaLinks ?? []];
                                newLinks.push('');
                                setAnketa({
                                    ...anketa,
                                    mediaLinks: newLinks,
                                })
                            }}
                        >
                            Добавить
                        </Button>
                    </FormControl>
                </>)}

                <h4>Ссылки на социальные сети</h4>
                <FormControl fullWidth sx={{m: 1}}>
                    <Grid container spacing={2}>
                        {anketa.socialLinks && anketa.socialLinks.map((link, key) => (<>
                            <Grid item xs={12} md={12}>
                                <TextField fullWidth label="Вставьте ссылку на профиль социальной сети"
                                           variant="outlined"
                                           value={link} onChange={(e) => {
                                    const newLinks = [...anketa.socialLinks];
                                    newLinks[key] = e.target.value;
                                    setAnketa({
                                        ...anketa,
                                        socialLinks: newLinks,
                                    })
                                }}/>
                            </Grid>
                        </>))}
                    </Grid>
                    <Button
                        variant="contained"
                        sx={{mt: 2}}
                        onClick={() => {
                            const newLinks = [...anketa?.socialLinks ?? []];
                            newLinks.push('');
                            setAnketa({
                                ...anketa,
                                socialLinks: newLinks,
                            })
                        }}
                    >
                        Добавить
                    </Button>
                </FormControl>

                {allowFields?.map && (<>
                    <h4>Захоронение</h4>
                    <FormControl fullWidth sx={{m: 1}}>
                        <TextField id="outlined-basic2" label="Наименование кладбища" variant="outlined"
                                   value={anketa.graveyard.name} onChange={(e) => {
                            setAnketa({
                                ...anketa,
                                graveyard: {
                                    ...anketa.graveyard,
                                    name: e.target.value,
                                }
                            })
                        }}/>
                    </FormControl>
                    <FormControl fullWidth sx={{m: 1}}>
                        <TextField id="outlined-basic2" label="Как найти захоронение" variant="outlined"
                                   value={anketa.graveyard.description} onChange={(e) => {
                            setAnketa({
                                ...anketa,
                                graveyard: {
                                    ...anketa.graveyard,
                                    description: e.target.value,
                                }
                            })
                        }}/>
                    </FormControl>
                    <MapSelect setNewCoords={setNewCoords} newCoords={newCoords} address={address}
                               setAddress={setAddress}/>
                </>)}
                    {allowFields?.customImage && (<>
                        <h4>Параметры для QR кода</h4>
                        <FormControl fullWidth sx={{m: 1}}>
                            <TextField value={anketa.customImageText} inputProps={{maxLength: 20}} onChange={
                                (e) => setAnketa({
                                    ...anketa,
                                    customImageText: e.target.value,
                                })
                            } id="outlined-basic2" label="1 строка. Максимальное количество символов - 20" variant="outlined"/>
                        </FormControl>
                        <FormControl fullWidth sx={{m: 1}}>
                            <TextField value={anketa.customImageText2} inputProps={{maxLength: 23}} onChange={
                                (e) => setAnketa({
                                    ...anketa,
                                    customImageText2: e.target.value,
                                })
                            } id="outlined-basic2" label="2 строка. Максимальное количество символов - 23" variant="outlined"/>
                        </FormControl>
                        <FormControl fullWidth sx={{m: 1}}>
                            <TextField value={anketa.customImageText3} inputProps={{maxLength: 23}} onChange={
                                (e) => setAnketa({
                                    ...anketa,
                                    customImageText3: e.target.value,
                                })
                            } id="outlined-basic2" label="3 строка. Максимальное количество символов - 25" variant="outlined"/>
                        </FormControl>
                        <FormControl fullWidth sx={{m: 1}}>
                            <InputLabel id="demo-simple-select-label3">Форма линий</InputLabel>
                            <Select
                                labelId="demo-simple-select-label3"
                                id="demo-simple-select3"
                                label="Форма линий"
                                onChange={(e) => {
                                    setAnketa({
                                        ...anketa,
                                        customQRCode: {
                                            ...anketa.customQRCode,
                                            type: e.target.value,
                                        }
                                    })
                                }}
                                value={anketa?.customQRCode?.type}
                            >
                                <MenuItem value='square'>Квадратная</MenuItem>
                                <MenuItem value='rounded'>Скругленная</MenuItem>
                                <MenuItem value='classy'>Стильная</MenuItem>
                                <MenuItem value='classy-rounded'>Стильная Округленная</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{m: 1, flexDirection: 'row', flexWrap: 'wrap'}}>
                            <LoadButton
                                variant="contained"
                                component="label"
                            >
                                <svg fill="#ffffff" width="50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path
                                        d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/>
                                </svg>
                                Загрузить изображение для QR кода
                                <input
                                    type="file"
                                    hidden
                                    onChange={(e) => {
                                        if (!e.target?.files?.length) return;
                                        const src = URL.createObjectURL(e.target.files[0])
                                        const _URL = window.URL || window.webkitURL;
                                        const img = new Image();
                                        if (e?.target?.files[0]) {
                                            const objectUrl = _URL.createObjectURL(e.target.files[0]);
                                            const blob = e.target.files[0];
                                            let cWidth: number, cHeight: number;
                                            const cDiff = img.height - img.width;
                                            if (cDiff === 0) {
                                                cWidth = 300;
                                                cHeight = 300;
                                            } else if (cDiff > 0) {
                                                cWidth = 400;
                                                cHeight = 300;
                                            } else {
                                                cWidth = 400;
                                                cHeight = 600;
                                            }
                                            img.onload = function () {
                                                const {width, height} = setupImageHeightWidthByContainer(cWidth, cHeight, img.width, img.height);
                                                setAnketa({
                                                    ...anketa,
                                                    customImage: {
                                                        ...anketa.customImage,
                                                        buffer: blob,
                                                        src,
                                                        width: width ? Math.ceil(width / 10) * 10 : 0,
                                                        height: height ? Math.ceil(height / 10) * 10 : 0,
                                                    }
                                                })
                                                _URL.revokeObjectURL(objectUrl);
                                            };
                                            img.src = objectUrl;
                                        }
                                    }}
                                />
                            </LoadButton>
                        </FormControl>
                    </>)}
                    <CustomQRCodeExampleContainer>
                        {anketa?.customImage?.src && <img src={anketa?.customImage?.src} width={anketa?.customImage?.width ?? 0}  height={anketa?.customImage?.height ?? 0} style={{
                            objectFit: 'contain',
                            display: 'inline-block',
                            position: 'absolute',
                            zIndex: 0,
                            maxWidth: '100%',
                        }}/>}
                        <div style={{zIndex: 1}} ref={refQR}/>
                    </CustomQRCodeExampleContainer>
                    {allowFields?.customImage && (<>
                        {anketa?.customImage?.src && (<>
                            <h4>Примеры применения QR кода</h4>
                            <p><b>ВНИМАНИЕ!</b> QR код ведет на главную страницу сайта. Для актуализации ссылки нужно создать
                                страницу
                            </p>
                            <QRCodeContainer>
                                <QRCodeConfigurator
                                    image={{
                                        src: anketa?.customImage?.src,
                                        params: {
                                            width: anketa?.customImage?.width ?? 0,
                                            height: anketa?.customImage?.height ?? 0,
                                        }
                                    }}
                                    qr={qrSrc}
                                    text={[{text: `${anketa.customImageText ?? anketa.name}`}, {text: `${anketa.customImageText2 ?? `${anketa.dateBorn} - ${anketa.dateEnd}`}`}, {text: anketa?.customImageText3 ?? ''}]}
                                    type={TypeConfigurator.board}/>
                                <QRCodeConfigurator
                                    image={{
                                        src: anketa?.customImage?.src,
                                        params: {
                                            width: anketa?.customImage?.width ?? 0,
                                            height: anketa?.customImage?.height ?? 0,
                                        }
                                    }}
                                    qr={qrSrc}
                                    text={[{text: `${anketa.customImageText ?? anketa.name}`}, {text: `${anketa.customImageText2 ?? `${anketa.dateBorn} - ${anketa.dateEnd}`}`}, {text: anketa?.customImageText3 ?? ''}]}
                                    type={TypeConfigurator.table}/>
                                <QRCodeConfigurator
                                    image={{
                                        src: anketa?.customImage?.src,
                                        params: {
                                            width: anketa?.customImage?.width ?? 0,
                                            height: anketa?.customImage?.height ?? 0,
                                        }
                                    }}
                                    qr={qrSrc}
                                    text={[{text: `${anketa.customImageText ?? anketa.name}`}, {text: `${anketa.customImageText2 ?? `${anketa.dateBorn} - ${anketa.dateEnd}`}`}, {text: anketa?.customImageText3 ?? ''}]}
                                    type={TypeConfigurator.memorial}/>
                            </QRCodeContainer>
                        </>)}
                    </>)}

                    <Button onClick={(e) => handleSubmit(e)} sx={{m: 1, width: '100%'}} variant="contained">
                        {tariffid ? 'Создать страницу' : 'Сохранить страницу'}
                    </Button>
            </Grid>
        </Container>
    </>
  );
}

export default ChangePage;